<script setup lang="ts">
import { computed } from '#imports'

const props = defineProps<{
  ariaLabel?: string
  disabled?: boolean
  label?: string
  modelValue: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const checked = computed({
  get: () => props.modelValue,
  set: (value: boolean) => {
    emit('update:modelValue', value)
  },
})
</script>

<template>
  <button
    class="group relative inline-flex cursor-pointer items-center outline-none"
    :disabled="disabled"
    type="button"
    @click="checked = !checked"
  >
    <input
      class="sr-only pointer-events-none"
      :aria-label="label ? label : ariaLabel"
      :checked="checked"
      :disabled="disabled"
      tabindex="-1"
      type="checkbox"
    />
    <div
      class="after:content-empty group-focus:ring-secondary-300 after-absolute h-6 w-11 rounded-full bg-neutral-200 after:left-0 after:top-0 after:h-5 after:w-5 after:translate-y-[2px] after:rounded-full after:border after:border-neutral-300 after:bg-white after:transition-all group-focus:outline-none group-focus:ring-4"
      :class="{
        'after:translate-x-.5': !checked,
        'bg-secondary-700 after:translate-x-5.5 after:border-white': checked,
        'bg-neutral-200 opacity-70 after:bg-neutral-50': disabled,
      }"
    />

    <span v-if="label" class="ml-3 text-sm font-medium text-neutral-900">
      {{ label }}
    </span>
  </button>
</template>
